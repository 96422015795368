import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools }          from "redux-devtools-extension";
import thunk                            from "redux-thunk";
import localForage                      from "localforage";
import { ENVIRONMENTS } 			    from "Store/constants";

// Import Own Components
import rootReducer from "./Reducers";

const persistConfig = {
	key       : "root",
	storage   : localForage,
	blacklist : ["carsReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const isProduction = ENVIRONMENTS.PRODUCTION  === process?.env?.NODE_ENV;

export const Store = createStore(
	persistedReducer,
	isProduction
		? applyMiddleware(thunk)
		: composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(Store);
