import React, { Suspense, lazy }   from "react";
import { Switch, Redirect} from "react-router-dom";

// Import Own Components
import {
	Loader,
	SessionFree,
	PrivateRoute,
} from "Helpers";

const Login          = lazy(() => import("./Login"));
const Register       = lazy(() => import("./Register"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const Documents      = lazy(() => import("./Documents"));

const Pages = () => (
	<Suspense fallback={<Loader />}>
		<Switch>
			<Redirect exact path="/" to="/documents" />
			<SessionFree exact path="/login" to="/documents" component={Login} />
			<SessionFree exact path="/register" to="/documents" component={Register} />
			<SessionFree exact path="/forgot_password" to="/documents" component={ForgotPassword} />

			<PrivateRoute exact path="/documents" component={Documents} />

			<Redirect to="/documents" />
		</Switch>
	</Suspense>
);

export default Pages;
