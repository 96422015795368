import { ThemeProvider  }          from "@material-ui/styles";
import { CssBaseline }             from "@material-ui/core";
import { PersistGate }             from "redux-persist/integration/react";
import { Provider }                from "react-redux";
import NProgress                   from "nprogress";
import { BrowserRouter as Router } from "react-router-dom";
import moment                      from "moment";
import "moment/locale/es";
import "nprogress/nprogress.css";
import "antd/dist/antd.css";

// Import Own Components
import { theme, Loader }    from "./Helpers";
import Pages                from "./Pages";
import { Store, persistor } from "./Store";

moment.locale("es");

NProgress.configure({ showSpinner : false });

const App = () => (
	<Router>
		<ThemeProvider theme={theme}>
			<Provider store={Store}>
				<PersistGate loading={<Loader />} persistor={persistor}>
					<CssBaseline />
					<Pages />
				</PersistGate>
			</Provider>
		</ThemeProvider>
	</Router>
);

export default App;
