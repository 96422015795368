// Auth
export const SET_USER_DATA   = "SET_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

// Documents
export const SET_YEAR                 = "SET_YEAR";
export const SET_DOCUMENTS            = "SET_DOCUMENTS";
export const SET_MONTH                = "SET_MONTH";
export const SET_DOCUMENTS_AMOUNT     = "SET_DOCUMENTS_AMOUNT";
export const SELECT_HOME              = "SELECT_HOME";
export const SELECT_YEAR              = "SELECT_YEAR";
export const SELECT_MONTH             = "SELECT_MONTH";
export const SELECT_DOCUMENTS_AMOUNT  = "SELECT_DOCUMENTS_AMOUNT"
export const CLEAR_DOCUMENTS          = "CLEAR_DOCUMENTS";
export const CLEAR_DOCUMENTS_AMOUNT   = "CLEAR_DOCUMENTS_AMOUNT";

export const TERMS_AND_CONDITIONS_URL = "https://revitaware.com/terminos-y-condiciones/"
export const PRIVACY_URL              = "https://revitaware.com/aviso-de-privacidad/"
export const GOOGLE_SCOPE_PROVIDER    = "https://www.googleapis.com/auth/contacts.readonly"
export const MONTHS                   = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
		
export const MESSAGES = {
    UPDATE_PREMIUM_ACCOUNT: "Para visualizar el ticket se necesita una cuenta PREMIUM",
    GET_PREMIUM: "¡Conviértete en Premium!",
    BILL_PORTAL: "Portal de facturación",
    INVALID_CREDENTIALS: "Correo o contraseña incorrectos",
    MANY_ATTEMPS: "Has intentado iniciar sesión demasiadas veces con credenciales erróneas. Espera un poco antes de intentarlo de nuevo",
    MISSING_CREDENTIALS: "¡Tienes que agregar un correo y contraseña!",
    EMAIL_VERIFICATION: "Se ha mandado un correo de verificación",
    FORGOT_EMAIL_VERIFICATION: "Se ha enviado un correo para reestablecer la contraseña",
    REGISTER_EMAIL_VERIFICATIOM: "Un correo electrónico ha sido enviado para validar la cuenta",
    EMAIL_CANNOT_BE_SENT: "No se pudo enviar el correo.",
    NO_DOCUMENTS: "Todavía no hay ningún documento en esta sección",
    COMMING_SOON: "Próximamente",
    THERE_NO_EMPTY_FIELDS: "Verifica que no existan campos vacíos",
    NOT_ROBOT: "Verifica que no eres un robot",
    PASSWORDS_DOESNT_MATCH: "Las contraseñas no coinciden",
    LEAK_PASSWORD: "Contraseña débil. Porfavor añade más caracteres",
    CANCEL_PREMIUM: "Cancelar subscripción",

}

export const ERROR_MESSAGE = {
    CHECKOUT: "No se ha podido efectuar el pago",
    REGISTER: "Ocurrió un error al registrarse, compruebe que la cuenta no exista",
}

export const USER_PLANS = {
    PREMIUM: "Premium",
    BASIC: "Básico",
    ENTREPRENEUR: "Empresario",
}

export const FB_TABLES = {
    USERS: "users",
    SUBSCRIPTIONS: "subscriptions",
    CHECKOUT_SESSIONS: "checkout_sessions",
    TICKETS: "tickets",
    CUSTOMERS: "customers",
}

export const FB_FIELDS = {
    CREATED: "created",
    USER_ID: "userID",
    CREATION_DATE: "creationDate",
    MARK_FOR_DELETION: "markForDeletion",
}

export const FB_CODES = {
    TOO_MANY_REQUESTS: "auth/too-many-requests",
}

export const ENVIRONMENTS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development'
}
