import { useEffect } from "react";
import nProgress     from "nprogress";

const Loader = () => {
	useEffect(() => {
		nProgress.start();

		return () => {
			nProgress.done();
		};
	}, []);

	return null;
};

export default Loader;
