import React      		from "react";
import { render } 		from "react-dom";
import { ENVIRONMENTS } from "Store/constants";

// Import Own Components
import App                            from "./App.jsx";
import reportWebVitals                from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";

render(<App />,document.getElementById('root'));

// Add Service worker in production
const isProduction = ENVIRONMENTS.PRODUCTION  === process.env.NODE_ENV;

if (isProduction) {
	serviceWorkerRegistration.unregister();	
} else {
	reportWebVitals();
	serviceWorkerRegistration.unregister();	
}
