import React               from "react";
import { connect }         from "react-redux";
import { Redirect, Route } from "react-router-dom";

const SessionFree = ({
	to        = "/home",
	loggedIn  = false,
	component : Component,
	render    : RenderComponent,
	...rest
}) => (
	<Route
		{...rest}
		render={props => (
			loggedIn
				? <Redirect to={to} />
				: RenderComponent
					? <RenderComponent {...props} />
					: <Component {...props} />
		)}
	/>
);

const mapStateToProps = ({ authReducer }) => ({ loggedIn : authReducer?.loggedIn ?? false });

export default connect(mapStateToProps)(SessionFree);
