import { combineReducers } from "redux";

// Import Own Components
import authReducer      from "./authReducer";
import documentsReducer from "./documentsReducer";

const rootReducer = combineReducers({
	authReducer,
	documentsReducer,
});

export default rootReducer;
