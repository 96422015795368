import {
	amber,
	blue,
	brown,
	cyan,
	deepOrange,
	deepPurple,
	green,
	indigo,
	lightBlue,
	lightGreen,
	orange,
	pink,
	purple,
	red,
	teal,
} from "@material-ui/core/colors";

const colors = [
	amber[500],
	blue[500],
	brown[500],
	cyan[500],
	deepOrange[500],
	deepPurple[500],
	green[500],
	indigo[500],
	lightBlue[500],
	lightGreen[500],
	orange[500],
	pink[500],
	purple[500],
	red[500],
	teal[500],
];

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
	max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomMaterialColor = () => {
	const index = getRandomInt(0, colors.length);

	const selectedColor = colors[index];

	return selectedColor;
};

export default randomMaterialColor;
