import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
} from "Store/constants";

const initialState = {
	userData : {},
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_DATA:
			return {
				...state,
				loggedIn : true,
				userData : {
					...state.userData,
					...action.payload.data,
				},
			};
		case CLEAR_USER_DATA:
			return {};
		default:
			return state;
	}
};

export default authReducer;
