import {
	SELECT_HOME,
	SELECT_YEAR,
	SELECT_MONTH,
	SELECT_DOCUMENTS_AMOUNT,
	SET_YEAR,
	SET_MONTH,
	SET_DOCUMENTS,
	SET_DOCUMENTS_AMOUNT,
	CLEAR_DOCUMENTS,
	CLEAR_DOCUMENTS_AMOUNT,
} from "Store/constants";

const initialState = {
	year      : null,
	month     : null,
	totalAmount : 0,
	documents : [],
};

const breadCrumbsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_DOCUMENTS:
		case CLEAR_DOCUMENTS_AMOUNT:
		case SELECT_HOME:
			return initialState;
		case SELECT_YEAR:
			return {
				...state,
				month : null,
			};
		case SELECT_MONTH:
			return {
				...state,
			};
		case SELECT_DOCUMENTS_AMOUNT:
			return {
					...state,
			};
		case SET_YEAR:
			return {
				year  : action.payload.year,
				month : null,
			};
		case SET_MONTH:
			if (!state.year) return initialState;

			return {
				...state,
				month : action.payload.month,
			};
		case SET_DOCUMENTS:
			return {
				...state,
				documents : action.payload.documents,
			};
		case SET_DOCUMENTS_AMOUNT:
			return {
				...state,
				totalAmount : action.payload.totalAmount,
			};
		default:
			return state;
	}
};

export default breadCrumbsReducer;
